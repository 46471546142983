import { ArrowLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import * as React from "react";
import { useState } from "react";
import Layout from "components/global/layout";
import Navbar from "components/global/navbar";
import PageTitle from "components/global/page-title";

import visaLogo from "images/visa-logo.svg";
import mcLogo from "images/mc-logo.svg";
import InputWithLabel from "components/forms/input-with-label";
import RadioInput from "components/forms/radio";
import AddressFields from "components/forms/address-fields";
import { Transition } from "@headlessui/react";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useStore } from "app/store/store";
import * as validate from "validate.js";
import { cardFieldsValidation, nameValidation } from "app/services/validation";
import { Link, navigate } from "gatsby";
import { createGuide } from "app/store/applicationReducer";
import { Warning, ErrorList } from "components/forms/warning";
// import { sendEmail } from "../app/services/backend";

// const sendE = async (guide, senderDetails, receiverDetails) => {
//   return await sendEmail(guide, senderDetails, receiverDetails);
// };

const baseStyle = {
  base: {
    fontSize: "12px",
    color: "#6b7280",
    "::placeholder": {
      color: "#9ca3af",
    },
  },
};
const cardElementOptions = {
  showIcon: true,
  iconStyle: "default",
  classes: {
    base: "input-field",
  },
  style: {
    ...baseStyle,
    // invalid: {
    //     color: '#9e2146',
    // },
  },
};

const hasfieldsErrors = (errors) => {
  if (!errors.length) return;
  console.log("errors", errors);
  return errors.join("\n");
};

const hasResults = (result) => {
  return Object.keys(result).length > 0 && !result.error;
};

const ResumentDelEnvio = () => {
  const [globalState, middleWareDispatcher, eventBus] = useStore();
  const [alternativeAddress, setAlternativeAddress] = useState(false);
  const [secondaryAddress, setSecondaryAddress] = useState({});
  const stripe = useStripe();
  const elements = useElements();
  const [fieldsErrors, setFieldsErrors] = useState("");
  const result = globalState.quote.result;
  // destructure payment intent
  const paymentIntentSecret = globalState.quote?.result?.client_secret;
  async function handleSubmit(e) {
    // basic reference
    e.preventDefault();
    const isFormValid = validateFields();
    if (isFormValid !== true) return;
    // https://github.com/stripe/react-stripe-js/blob/master/examples/hooks/0-Card-Minimal.js
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      eventBus.publish("NOTIFICATION", {
        title: "Error",
        type: "error",
        message:
          "No se ha podido continuar con el pago, aún no esta listo el procesador de pago",
      });
      return;
    }
    eventBus.publish("NOTIFICATION", {
      title: "Procesando pago...",
      type: "info",
      message: "Espere un momento por favor.",
    });
    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const card = elements.getElement(CardNumberElement);
    const cardDetails = alternativeAddress
      ? secondaryAddress
      : globalState.senderDetails;
    // some details com from the sender details others from this form
    const billingDetails = {
      name: secondaryAddress.name,
      email: globalState.senderDetails.email,
      phone: globalState.senderDetails.phone,
      address: {
        city: cardDetails.city,
        country: "MX",
        line1: `${cardDetails.street} ${cardDetails.exterior} ${cardDetails.interior}`,
        line2: `${cardDetails.colony} ${cardDetails.municipality} ${cardDetails.reference_street}`,
        postal_code: cardDetails.postalcode,
        state: globalState.quote.params.origin,
      },
    };
    // validate the fields
    if (!billingDetails.name) {
      eventBus.publish("NOTIFICATION", {
        title: "Falta información de la tarjeta",
        type: "error",
        message: "El nombre en la tarjeta es requerido",
        duration: 2000,
      });
      return;
    }

    if (!paymentIntentSecret) {
      eventBus.publish("NOTIFICATION", {
        title: "Falta una cotización",
        message: "Aún no hay una cotización para pagar",
        type: "error",
        duration: 2000,
      });
      return;
    }

    const { error, paymentIntent } = await stripe.confirmCardPayment(
      paymentIntentSecret,
      {
        payment_method: {
          // get this either from stored data or fron the alternative form fields
          billing_details: billingDetails,
          // optional
          // metadata: {
          //     order_id: 'testorder'
          // },
          card,
        },
      }
    );
    if (error) {
      // payment errored
      eventBus.publish("NOTIFICATION", {
        title:
          error.type === "validation_error"
            ? "Falta información de la tarjeta"
            : "Pago no procesado",
        message: error.message,
        type: "error",
        duration: 2000,
      });
      // check wether the guide has already been processed
      if (error.type === "StripeCardError") {
        // setStripeErrorMessage(error.message)
      }
      // middleWareDispatcher(createGuide(globalState, error))
    } else {
      // dispatch an event to create the guide
      // use our backend to create verify that the payment has been processed
      // and then create the guide
      // dispatch event from the function
      middleWareDispatcher(createGuide(globalState, paymentIntent));
      navigate("/realizar_pago");
      // payment processed
    }
    // how to charge now?
  }

  const handleDetailsChange = (event) => {
    const { name, value } = event.target;
    // bail if its the card name field, that one isnt required to be saved
    if (name === "alternative_address") return;
    // save value to new state
    const newState = { ...secondaryAddress, [name]: value };
    setSecondaryAddress(newState);
  };

  function validateFields() {
    const cardDetails =
      alternativeAddress === true
        ? secondaryAddress
        : globalState.senderDetails;
    let fieldsValidated;
    if (alternativeAddress === false) {
      cardDetails.name = secondaryAddress.name;
      fieldsValidated = validate(cardDetails, { name: nameValidation });
    } else {
      fieldsValidated = validate(cardDetails, cardFieldsValidation);
    }

    if (fieldsValidated !== null && fieldsValidated !== undefined) {
      setFieldsErrors(Object.values(fieldsValidated));
      return Object.values(fieldsValidated);
    } else {
      setFieldsErrors("");
    }

    return true;
  }
  return (
    <>
      <Navbar />
      <Layout>
        <PageTitle>Resumen del envío</PageTitle>
        {hasResults(result) && (
          // has results
          <div className="mt-4 bg-gray-400 bg-opacity-10 px-4 py-6 rounded-md mb-56 md:mb-40">
            <form onSubmit={handleSubmit} onChange={handleDetailsChange}>
              {/* bi column grid */}
              <div className="md:flex md:justify-between space-x-6 flex-wrap">
                <div className="md:w-5/12">
                  <h2 className="text-primary-light-red font-display font-bold text-lg uppercase">
                    Aerocharter plus
                  </h2>
                  <div className="flex justify-between text-sm">
                    <span>{result.estado_origen}</span>
                    <ChevronRightIcon className="w-4 h-4" />
                    <span>{result.estado_destino}</span>
                  </div>
                  <div className="py-8">
                    <h3 className="text-primary-dark-blue text-xs">
                      Total Envío
                    </h3>
                    <p className="uppercase text-primary-light-green text-3xl font-display font-bold">
                      <span>
                        <span>$ {parseFloat(result.costo).toFixed(2)} MX </span>
                      </span>
                    </p>
                  </div>
                </div>
                {/* stripeErrorMessage && <pre>{stripeErrorMessage}</pre> */}
                {/* payment form */}
                {/* start form group */}
                <div className="md:w-6/12">
                  {hasfieldsErrors(fieldsErrors) && (
                    <Warning>
                      <ErrorList errors={fieldsErrors} />
                    </Warning>
                  )}
                  <div>
                    <div className="flex justify-between items-center">
                      <span>Pago con tarjeta de crédito o débito</span>
                      <div className="grid grid-cols-2 gap-2">
                        <img src={visaLogo} alt="visa" className="w-8 h-8 " />
                        <img
                          src={mcLogo}
                          alt="master card"
                          className="w-8 h-8"
                        />
                      </div>
                    </div>
                    <div className="mt-2 flex-col space-y-4">
                      <div className="space-y-2">
                        {/* <CardElement options={cardElementOptions} /> */}
                        <CardNumberElement options={cardElementOptions} />
                        {/* <InputWithLabel type="number" placeholder="No. en la tarjeta" /> */}
                        <InputWithLabel
                          type="text"
                          name="name"
                          placeholder="Nombre que aparece en la tarjeta"
                        />
                        <div className="flex space-x-2">
                          <CardExpiryElement
                            options={{
                              style: { ...baseStyle },
                              classes: { base: "input-field w-1/2" },
                            }}
                          />
                          <CardCvcElement
                            options={{
                              style: { ...baseStyle },
                              classes: { base: "input-field w-1/2" },
                            }}
                          />
                        </div>
                      </div>
                      <RadioInput
                        label="Utilizar la misma dirección de recolección"
                        id="same-address"
                        name="alternative_address"
                        checked={!alternativeAddress}
                        onChange={() => {
                          setFieldsErrors("");
                          return setAlternativeAddress(false);
                        }}
                      />
                      <RadioInput
                        label="Agregar dirección diferente de tarjeta"
                        id="other-address"
                        name="alternative_address"
                        checked={alternativeAddress}
                        onChange={() => setAlternativeAddress(true)}
                      />
                    </div>
                  </div>
                  {/* end payment form */}
                  {/* optional address */}
                  <Transition
                    show={alternativeAddress}
                    unmount={true}
                    enter="transition-opacity duration-150"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <AddressFields details={secondaryAddress} />
                  </Transition>

                  {/* end of form group */}
                </div>
                <hr className="border-gray-300 mb-4 mt-8 w-full" />
                <div className="flex justify-center space-x-1  w-full ">
                  <Link
                    to="/realizar-envio"
                    state={{ step: "receiverDetails" }}
                    className="btn-shape text-white w-auto px-4 bg-primary-light-red hover:bg-opacity-90"
                  >
                    <ArrowLeftIcon className="w-4 h-4" />
                    Atras
                  </Link>
                  <button
                    className="btn-shape text-white bg-primary-light-red hover:bg-opacity-90 disabled:bg-opacity-50"
                    type="submit"
                    disabled={!stripe}
                  >
                    Realizar Pago
                  </button>
                </div>
              </div>
              {/* end f bicolumn grid */}
            </form>
          </div>
        )}

        {
          // no quote results yet
          !hasResults(result) && (
            <div className="bg-gray-200 bg-opacity-30 rounded-md rounded-b-none text-primary-dark-blue px-6 py-6 my-6 flex flex-col items-center justify-center">
              <h2 className="uppercase text-center font-display font-bold text-2xl py-4 text-primary-dark-blue">
                Aún no has hecho una cotización.
              </h2>
              <div className="flex-col my-6">
                <Link
                  to="/cotizar-envio"
                  className="flex text-primary-light-red w-full items-center justify-center my-4 text-base font-semibold"
                >
                  <ArrowLeftIcon className="h-4 w-4 mr-2" />
                  <span>Pedir Cotización</span>
                </Link>
              </div>
            </div>
          )
        }
      </Layout>
    </>
  );
};

export default ResumentDelEnvio;
