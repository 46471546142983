import * as React from 'react'

import { classNames } from '../helpers'

const RadioInput = ({ label, labelIsTitle, name, id, onChange, checked=false, className='', ...props }) => {
    return (
        <div className={`flex items-center ${className}`} >
            <input
                id={id}
                name={name}
                type="radio"
                checked={checked}
                onChange={onChange}
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
            />
            {
                // if label :
                label ?
                    (
                        <label htmlFor={id} className={
                            classNames('ml-2 block text-xsm font-medium text-primary-dark-blue',
                                (labelIsTitle ? 'base-title text-primary-gray' : ''))}>
                            {label}
                        </label>

                    ) :
                    // no label
                    ''
            }
        </div>
    )
}

export default RadioInput